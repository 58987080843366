import React from 'react';
import Layout from '../components/Layout';
import {Container, Divider, Grid, ListItemIcon, ListItemText, makeStyles, MenuItem} from "@material-ui/core";
import {graphql, useStaticQuery} from "gatsby"
import SideNavigation from "../components/SideNavigation";
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles(theme => ({
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
}));

export default function App() {

    const classes = useStyles();

    const data = useStaticQuery(graphql`
    {
      rechtsgebieteVerkehrsrecht: allContentfulRechtsgebiet(filter: {category: {eq: "Verkehrsrecht"}}, sort: {fields: weight, order: ASC}) {
        rechtsgebiete:nodes {
          slug
          category
          title
          weight
        }
      }
      rechtsgebieteBaurecht: allContentfulRechtsgebiet(filter: {category: {eq: "Baurecht"}}, sort: {fields: weight, order: ASC}) {
        rechtsgebiete:nodes {
          slug
          category
          title
          weight
        }
      }
      rechtsgebieteKommunen: allContentfulRechtsgebiet(filter: {category: {eq: "Beratung für Kommunen"}}, sort: {fields: weight, order: ASC}) {
        rechtsgebiete:nodes {
          slug
          category
          title
          weight
        }
      }
      allCategories: allContentfulKategorie {
        categories:nodes {
          title
          slug
        }
      }
      heroImage:file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "lobby-1.jpg"}) {
        img:childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

    return (
        <Layout heroHeadline1="Rechtsgebiete"
                heroImage={data.heroImage.img.fluid}
        >
            <Container className={classes.cardGrid}>
                <Grid
                    spacing={10}// Add it here :)
                    container
                >
                    <Grid item xs={12} sm={6}>
                        <Divider/>

                        <SideNavigation
                            initialState={false}
                            navItems={data.rechtsgebieteVerkehrsrecht}
                            currentCategory={"Verkehrsrecht"}
                            categories={data.allCategories.categories}
                        >
                            <MenuItem button className={classes.nested}
                                      component="a"
                                      href="https://pfeiffer-muenchen.adac-vertragsanwalt.de/"
                                      target="_blank">
                                <ListItemIcon>
                                    <SendIcon/>
                                </ListItemIcon>
                                <ListItemText primary="ADAC-Vertragsanwältin"/>
                            </MenuItem>
                        </SideNavigation>
                        <Divider/>

                        <SideNavigation
                            initialState={false}
                            navItems={data.rechtsgebieteBaurecht}
                            currentCategory={"Baurecht"}
                            categories={data.allCategories.categories}
                        >
                        </SideNavigation>
                        <Divider/>
                        <SideNavigation
                            initialState={false}
                            navItems={data.rechtsgebieteKommunen}
                            currentCategory={"Beratung für Kommunen"}
                            categories={data.allCategories.categories}
                        >
                        </SideNavigation>
                        <Divider/>
                    </Grid>

                    <Grid item xs={12} sm={12}>

                    </Grid>
                </Grid>
            </Container>
        </Layout>
    );
}
